import { Box, Divider, Stack } from "@mui/material";
import axios from "axios";
import CustomBooleanField from "components/commons/fields/CustomBooleanField";
import { baseResources } from "config_infos";
import { rteGroupTypeChoices } from "enums/reactAdminChoices";
import { useCallback, useEffect, useState } from "react";
import {
  Datagrid,
  DateField,
  Labeled,
  ReferenceArrayField,
  ReferenceField,
  SelectField,
  TextField,
  useRecordContext,
} from "react-admin";

export default function GroupBasicInfos() {
  return (
    <Box p={2}>
      <Stack direction={"row"} spacing={4}>
        <Labeled source="name">
          <TextField source="name" fullWidth />
        </Labeled>
        <Labeled source="code">
          <TextField source="code" fullWidth />
        </Labeled>
        <Labeled source="code_tm">
          <TextField source="code_tm" fullWidth />
        </Labeled>
        <Labeled source="mode">
          <TextField source="mode" fullWidth />
        </Labeled>
        <Labeled source={"type"}>
          <SelectField source={"type"} choices={rteGroupTypeChoices} />
        </Labeled>
      </Stack>
      <Divider sx={{ p: 2 }} />
      <Stack>
        <Labeled source={"Sites"} m={2}>
          <ReferenceArrayField source="site_ids" reference={baseResources.sites.SITES} label="Sites">
            <StatusList />
          </ReferenceArrayField>
        </Labeled>
      </Stack>
    </Box>
  );
}

const StatusList = () => {
  const { site_ids } = useRecordContext();
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchStatuses = useCallback(async (site_id: number) => {
    setLoading(true);
    const { data } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_SITE_API_URL}/${baseResources.sites.STATUSES}`,
      params: {
        page: 1,
        size: 1,
        site_id,
        order_by: "-timestamp",
      },
    });
    return data.items;
  }, []);

  useEffect(() => {
    const calls = site_ids.map((site_id: number) => fetchStatuses(site_id));
    Promise.allSettled(calls)
      .then((results) => {
        let fetchedStatuses: any = [];
        results.forEach((result) => {
          if (result.status === "rejected") {
            throw new Error(`Failed to fetch data: ${result.reason}`);
          }
          if (result.value) {
            fetchedStatuses = [...fetchedStatuses, ...result.value];
          }
        });
        setStatuses(fetchedStatuses);
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  }, [site_ids, fetchStatuses]);

  return (
    <Datagrid data={statuses} total={statuses.length} isLoading={loading} bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField source="site_id" reference={baseResources.sites.SITES} link="show" />
      <DateField source="timestamp" locales={"fr-FR"} showTime />
      <CustomBooleanField source="network" />
      <CustomBooleanField source="control" />
      <CustomBooleanField source="measure" />
      <CustomBooleanField source="sequence" />
      <CustomBooleanField source="power_clamping" />
      <CustomBooleanField source="aggregator_module" />
    </Datagrid>
  );
};
