export enum SiteFrenchDsoEnum {
  enedis = "enedis",
  geredis = "geredis",
}

export enum SiteFinlandDsoEnum {
  elenia = "elenia",
  caruna = "caruna",
  savon_voima = "savon_voima",
  jse = "jse",
  kymenlaakson = "kymenlaakson",
  pks = "pks",
}

export enum allDsoEnum {
  enedis = "enedis",
  geredis = "geredis",
  elenia = "elenia",
  caruna = "caruna",
  savon_voima = "savon_voima",
  jse = "jse",
  kymenlaakson = "kymenlaakson",
  pks = "pks",
}

export enum SiteContryEnum {
  Finland = "Finland",
  France = "France",
}

export enum SiteOperatorEnum {
  NW_JMET = "NW JMET",
  NW_JMET2 = "NW JMET2",
  NW_JMET3 = "NW JMET3",
  NWJ_FIN_Oy = "NWJ FIN Oy",
}

export enum SiteFranceSupplierEnum {
  NWRE = "NWRE",
  ELMY = "ELMY",
}

export enum SiteFinlandSupplierEnum {
  NWRE = "NWRE",
  Entelios = "Entelios",
}

export enum RteComputationVersionEnum {
  soc_reserve = 1,
  t_tenue = 2,
}

export enum ChronicleFilenamesEnum {
  chronique_41h_v3 = "chronique_41h_v3.csv",
  essai_1bis = "essai_1bis.csv",
  essai_2v2 = "essai_2v2.csv",
  essai_3v2 = "essai_3v2.csv",
  essai_4v2 = "essai_4v2.csv",
  essai_5 = "essai_5.csv",
  chronique_32h = "chronique_32h.csv",
  chronique_36h = "chronique_36h.csv",
  r2_essai_2_file1 = "r2_essai_2_file1.csv",
  r2_essai_2_file2 = "r2_essai_2_file2.csv",
  r2_essai_1_file1 = "r2_essai_1_file1.csv",
  r2_essai_1_file2 = "r2_essai_1_file2.csv",
  yearly_performance_evaluation_10_racks_jbox1 = "yearly_performance_evaluation_10_racks_jbox1.csv",
  yearly_performance_evaluation_8_racks_jbox1 = "yearly_performance_evaluation_8_racks_jbox1.csv",
  fin_msi_roundtrip_pilote_project_29h = "fin_msi_roundtrip_pilote_project_29h.csv",
  fr_msi_8_racks_jbox1_36h = "fr_msi_8_racks_jbox1_36h.csv",
  fr_msi_jbox2_42h30 = "fr_msi_jbox2_42h30.csv",
  fr_performance_8_racks_jbox1_17h = "fr_performance_8_racks_jbox1_17h.csv",
  fr_performance_10_racks_jbox1_18h = "fr_performance_10_racks_jbox1_18h.csv",
}

export enum PlanningModeEnum {
  soc_management = "soc_management",
  fcr_rte_v2 = "fcr_rte_v2",
  fcr_rte_v2_no_reserve = "fcr_rte_v2_no_reserve",
  afrr_rte = "afrr_rte",
  afrr_fcr_rte = "afrr_fcr_rte",
  power_chronicle = "power_chronicle",
  power = "power",
  stop = "stop",
  fingrid_fcrn = "fingrid_fcrn",
}

export enum PlanningMaskModeEnum {
  r2_tests = "r2_tests",
  r2_chronicles = "r2_chronicles",
  r1_chronicles = "r1_chronicles",
  enspired = "enspired",
  r1_r2_mask = "r1_r2_mask",
  technical_chronicle = "technical_chronicle",
  arbitrage_spot = "arbitrage_spot",
  fake_box_staging = "fake_box_staging",
  standby_france = "standby_france",
  fcrn_standalone = "fcrn_standalone",
  fcrd_standalone = "fcrd_standalone",
  fcrd_fcrn_combined = "fcrd_fcrn_combined",
  r2_finland = "r2_finland",
  standby_finland = "standby_finland",
}

export enum RteGroupTypeEnum {
  rte_edr = "rte_edr",
  rte_edp = "rte_edp",
  rte_edc = "rte_edc",
  rte_eda = "rte_eda",
  fingrid_pe = "fingrid_pe",
  fingrid_ro = "fingrid_ro",
}

export enum GroupModeEnum {
  prod = "prod",
  test = "test",
}

export enum GroupTypeEnum {
  rte_edr = "rte_edr",
  rte_edp = "rte_edp",
  rte_edc = "rte_edc",
  rte_eda = "rte_eda",
  fingrid_pe = "fingrid_pe",
  fingrid_ro = "fingrid_ro",
}
