import { Box, Divider, Grid, Stack, Step, StepLabel, Stepper } from "@mui/material";
import ExplicitValuesBoolenInput from "components/commons/inputs/ExplicitValuesBooleanInput";
import { baseResources } from "config_infos";
import { SITE_MODES } from "enums";
import { RteComputationVersionEnum, SiteContryEnum, SiteOperatorEnum } from "enums/globalEnums";
import {
  rteComputationVersionChoices,
  siteCountryChoices,
  siteFinlandDsoChoices,
  siteFinlandSupplierChoices,
  siteFranceSupplierChoices,
  siteFrenchDsoChoices,
  siteOperatorChoices,
} from "enums/reactAdminChoices";
import { getDataproviderCustomErrorMessage } from "errors/handlers";
import useStepper from "hooks/useStepper";
import React, { useEffect, useMemo } from "react";
import {
  AutocompleteArrayInput,
  Button,
  DeleteButton,
  FormDataConsumer,
  FormDataConsumerRenderParams,
  NumberInput,
  ReferenceArrayInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect,
} from "react-admin";
import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form";
import { siteValidations } from "./validations";

type SitesBaseFormProps = {
  isCreateForm?: boolean;
};

type InputContainerProps = {
  source: string;
  InputType?: React.ComponentType<any>;
  gridItemProps?: any;
  inputProps?: any;
};

const InputContainer = ({ source, InputType = TextInput, gridItemProps, inputProps }: InputContainerProps) => {
  return (
    <Grid item xs={4} {...gridItemProps}>
      <InputType fullWidth source={source} {...inputProps} />
    </Grid>
  );
};

export default function SitesBaseForm({ isCreateForm = true }: SitesBaseFormProps) {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const record = useRecordContext();
  const { activeStep, handleNext, handleBack } = useStepper();

  // -----------------------------
  const steps = [
    "Basic Information",
    "Aggregation Details",
    "Location Information",
    "Network Information",
    "Capacity and Efficiency",
    "Advanced Settings",
    ...(isCreateForm ? ["Site Configuration"] : []),
  ];

  const handleSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      if (isCreateForm) {
        await dataProvider.create(baseResources.sites.SITES, { data });
        notify("Site and configuration created successfully", { type: "success" });
      } else {
        await dataProvider.update(baseResources.sites.SITES, { id: data.id, data, previousData: record });
        notify("Site updated successfully", { type: "success" });
      }
      redirect("list", baseResources.sites.SITES);
    } catch (error: any) {
      notify(getDataproviderCustomErrorMessage(error), { type: "error" });
    }
  };

  return (
    <SimpleForm mode="onBlur" validate={siteValidations} onSubmit={handleSubmit} toolbar={false}>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Divider sx={{ mt: 4, mb: 4 }} />
        <Stack
          direction="column"
          spacing={2}
          sx={{
            justifyContent: "space-between",
            height: "100%",
            mt: 2,
            minHeight: "500px",
          }}
        >
          <Box>
            {activeStep === 0 && (
              <Grid container spacing={2}>
                {isCreateForm && <InputContainer InputType={NumberInput} source="id" />}
                <InputContainer source="name" />
                <InputContainer InputType={SelectInput} source="country" inputProps={{ choices: siteCountryChoices }} />
                <FormDataConsumer>
                  {(formDataConsumerProps) => <CountryDependentInputs {...formDataConsumerProps} />}
                </FormDataConsumer>
                <Grid item xs={4}>
                  <ExplicitValuesBoolenInput label="IECharge" falseLabel="No" trueLabel="Yes" source="iecharge" />
                </Grid>
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid container spacing={2}>
                <FormDataConsumer>
                  {({ formData }) => {
                    if (formData.country !== SiteContryEnum.France) return <></>;
                    return <InputContainer source="rte_code" inputProps={{ label: "Tranche TM site" }} />;
                  }}
                </FormDataConsumer>
                <InputContainer InputType={SelectInput} source="mode" inputProps={{ choices: SITE_MODES }} />
                <InputContainer
                  InputType={ReferenceArrayInput}
                  source="group_ids"
                  inputProps={{
                    reference: baseResources.sites.GROUPS,
                    child: <AutocompleteArrayInput optionText="name" />,
                  }}
                />
              </Grid>
            )}
            {activeStep === 2 && (
              <Grid container spacing={2}>
                <InputContainer source="city" />
                <InputContainer InputType={NumberInput} source="zipcode" />
                <InputContainer InputType={NumberInput} source="latitude" />
                <InputContainer InputType={NumberInput} source="longitude" />
              </Grid>
            )}
            {activeStep === 3 && (
              <Grid container spacing={2}>
                <InputContainer source="network_ip" />
                <InputContainer source="aggregator_module_healthcheck_url" />
              </Grid>
            )}
            {activeStep === 4 && (
              <Grid container spacing={2}>
                <InputContainer InputType={NumberInput} source="max_cycles" />
                <InputContainer InputType={NumberInput} source="rack_count" />
                <InputContainer InputType={NumberInput} source="min_power_mw" />
                <InputContainer InputType={NumberInput} source="max_power_mw" />
                <InputContainer InputType={NumberInput} source="efficiency" />
                <InputContainer InputType={NumberInput} source="max_soc_mwh" />
                <InputContainer InputType={NumberInput} source="soh" />
              </Grid>
            )}
            {activeStep === 5 && (
              <Grid container spacing={2}>
                <InputContainer InputType={NumberInput} source="useful_energy_at_1mw" />
              </Grid>
            )}
            {activeStep === 6 && isCreateForm && (
              <Grid container spacing={2}>
                <InputContainer
                  InputType={NumberInput}
                  source="desired_configuration.power_mw"
                  inputProps={{ defaultValue: 1 }}
                />
                <InputContainer
                  InputType={NumberInput}
                  source="desired_configuration.efficiency_roundtrip"
                  inputProps={{
                    defaultValue: 0.85,
                  }}
                />
                <InputContainer
                  InputType={SelectInput}
                  source="desired_configuration.rte_fcr_computation_version"
                  inputProps={{
                    choices: rteComputationVersionChoices,
                    defaultValue: RteComputationVersionEnum.t_tenue,
                  }}
                />
              </Grid>
            )}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end", mt: 2, gap: 2 }}>
            <Button disabled={activeStep === 0} onClick={handleBack} label="Back" size="large" variant="outlined" />
            {activeStep === steps.length - 1 ? (
              <SaveButton label="Submit" size="large" />
            ) : (
              <Button variant="contained" color="primary" onClick={handleNext} label="Next" size="large" />
            )}
            {!isCreateForm && <DeleteButton />}
          </Box>
        </Stack>
      </Box>
    </SimpleForm>
  );
}

const CountryDependentInputs = ({ formData }: FormDataConsumerRenderParams) => {
  const { setValue } = useFormContext();
  const { country } = formData;

  const isFinland = country === SiteContryEnum.Finland;
  const disabled = !country;
  const helperText = disabled ? "You must choose a country first" : undefined;

  // Choices for DSO, supplier, and operator based on the country
  const dsoChoices = isFinland ? siteFinlandDsoChoices : siteFrenchDsoChoices;
  const supplierChoices = isFinland ? siteFinlandSupplierChoices : siteFranceSupplierChoices;

  const operatorChoices = useMemo(() => {
    return siteOperatorChoices.filter(({ id }) =>
      isFinland ? id === SiteOperatorEnum.NWJ_FIN_Oy : id !== SiteOperatorEnum.NWJ_FIN_Oy,
    );
  }, [isFinland]);

  // Reset fields when country changes
  useEffect(() => {
    setValue("dso", "");
    setValue("site_operator", "");
    setValue("supplier", "");
  }, [country, setValue]);

  const defaultInputProps = {
    disabled,
    helperText,
  };

  return (
    <>
      <InputContainer InputType={SelectInput} source="dso" inputProps={{ choices: dsoChoices, ...defaultInputProps }} />
      <InputContainer
        InputType={SelectInput}
        source="site_operator"
        inputProps={{ choices: operatorChoices, label: "SPV", ...defaultInputProps }}
      />
      <InputContainer
        InputType={SelectInput}
        source="supplier"
        inputProps={{ choices: supplierChoices, ...defaultInputProps }}
      />
      <InputContainer
        source="prm"
        inputProps={{ label: isFinland ? "Consumption point number (GSRN)" : "PRM", ...defaultInputProps }}
      />
    </>
  );
};
