import { enumToChoices } from "helpers/reactAdminHelpers";
import {
  SiteContryEnum,
  SiteFinlandDsoEnum,
  SiteFrenchDsoEnum,
  SiteOperatorEnum,
  SiteFranceSupplierEnum,
  SiteFinlandSupplierEnum,
  RteComputationVersionEnum,
  ChronicleFilenamesEnum,
  PlanningMaskModeEnum,
  RteGroupTypeEnum,
  GroupModeEnum,
  allDsoEnum,
  GroupTypeEnum,
} from "./globalEnums";

export type RaChoiceType = {
  id: string;
  name: string;
};

export const siteFrenchDsoChoices = enumToChoices(SiteFrenchDsoEnum);
export const siteFinlandDsoChoices = enumToChoices(SiteFinlandDsoEnum);
export const allDsoChoices = enumToChoices(allDsoEnum);

export const siteCountryChoices = enumToChoices(SiteContryEnum);

export const siteOperatorChoices = enumToChoices(SiteOperatorEnum);

export const siteFranceSupplierChoices = enumToChoices(SiteFranceSupplierEnum);
export const siteFinlandSupplierChoices = enumToChoices(SiteFinlandSupplierEnum);

export const rteComputationVersionChoices = enumToChoices(RteComputationVersionEnum);

export const chronicleFilenamesChoices = enumToChoices(ChronicleFilenamesEnum);

export const planningMaskModeChoices = enumToChoices(PlanningMaskModeEnum);

export const rteGroupTypeChoices = enumToChoices(RteGroupTypeEnum);

export const groupModeChoices = enumToChoices(GroupModeEnum);
export const groupTypeChoices = enumToChoices(GroupTypeEnum);
