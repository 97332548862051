import { Alert, AlertColor } from "@mui/material";
import axios from "axios";
import FormContainer from "components/commons/forms/FormContainer";
import { min } from "components/entities/maintenances/maintenanceHelpers";
import { validateMaintenanceEndDate, validatePlanningTime } from "components/validations";
import { baseResources } from "config_infos";
import { MAINTENANCE_TYPE } from "enums";
import { useEffect, useState } from "react";
import {
  AutocompleteArrayInput,
  Create,
  DateTimeInput,
  ReferenceArrayInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useStore,
} from "react-admin";
import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form";

export const MaintenanceCreate = () => {
  const [resultMessages, setResultMessages] = useState<string[]>([]);

  const handleSubmit: SubmitHandler<FieldValues> = async ({ site_ids, ...data }) => {
    setResultMessages([]); // Reset the messages state initially

    const url = `${process.env.REACT_APP_MAINTENANCE_API_URL}/${baseResources.maintenance.MAINTENANCE}`;
    const requests = site_ids.map((site_id: number) =>
      axios.post(url, {
        ...data,
        site_id,
      }),
    );
    // Execute all requests simultaneously
    const results = await Promise.allSettled(requests).catch();

    const errorMessages = results.map((result: any, index) => {
      const siteId = site_ids[index];
      if (result.status === "rejected") {
        return `error  Site ${siteId} : ${result.reason.response.data.detail.error}`;
      } else {
        return `success Site ${siteId} : Maintenace created`;
      }
    });

    setResultMessages(errorMessages);
  };
  return (
    <Create redirect="list">
      <SimpleForm mode="onBlur" onSubmit={handleSubmit}>
        <FormContainer>
          <SelectInput source="type" choices={MAINTENANCE_TYPE} validate={required()} />

          <DefaultValueSiteReferenceInput />
          <DateTimeInput
            source="start"
            validate={[validatePlanningTime, required()]}
            inputProps={{
              min,
            }}
          />
          <DateTimeInput
            source="end"
            inputProps={{
              min,
            }}
            validate={[validatePlanningTime, validateMaintenanceEndDate, required()]}
          />
          <TextInput source="operator" validate={required()} />
          <TextInput source="comment" multiline />
        </FormContainer>
      </SimpleForm>
      {resultMessages && resultMessages.length > 0 && (
        <div>
          {resultMessages.map((msg, index) => {
            const serverity = msg.split(" ")[0];
            return (
              <Alert key={index} severity={serverity as AlertColor}>
                {msg}
              </Alert>
            );
          })}
        </div>
      )}
    </Create>
  );
};

const DefaultValueSiteReferenceInput = () => {
  const [siteShowId] = useStore(`${baseResources.maintenance.MAINTENANCE}.list.siteShowId`);
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue("site_ids", [siteShowId]);
  }, [siteShowId, setValue]);

  return (
    <ReferenceArrayInput source="site_ids" reference={baseResources.sites.SITES} perPage={100}>
      <AutocompleteArrayInput
        validate={required()}
        label="Site list"
        optionText={({ id, name }: any) => {
          return `${id} - ${name} `;
        }}
        blurOnSelect={false}
        disableCloseOnSelect
      />
    </ReferenceArrayInput>
  );
};
