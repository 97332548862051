import { Admin, AppBar, CustomRoutes, Layout, Resource } from "react-admin";

import { Route } from "react-router-dom";

import { AppMenu } from "./menu";

import { GroupCreate } from "components/entities/site_service/groups/GroupCreate";
import { GroupEdit } from "components/entities/site_service/groups/GroupEdit";
import { GroupList } from "components/entities/site_service/groups/GroupList";
import { GroupShow } from "components/entities/site_service/groups/GroupShow";

import { PACreate } from "components/entities/topase_service/pa/PACreate";
import { PAEdit } from "components/entities/topase_service/pa/PAEdit";
import { PAList } from "components/entities/topase_service/pa/PAList";
import { PAShow } from "components/entities/topase_service/pa/PAShow";

import { RTE_GateCreate } from "components/entities/topase_service/rte_gate/RTE_GateCreate";
import { RTE_GateEdit } from "components/entities/topase_service/rte_gate/RTE_GateEdit";
import { RTE_GateList } from "components/entities/topase_service/rte_gate/RTE_GateList";
import { RTE_GateShow } from "components/entities/topase_service/rte_gate/RTE_GateShow";

import { RTE_Gate_StatusCreate } from "components/entities/topase_service/rte_gate_status/RTE_Gate_StatusCreate";
import { RTE_Gate_StatusEdit } from "components/entities/topase_service/rte_gate_status/RTE_Gate_StatusEdit";
import { RTE_Gate_StatusList } from "components/entities/topase_service/rte_gate_status/RTE_Gate_StatusList";
import { RTE_Gate_StatusShow } from "components/entities/topase_service/rte_gate_status/RTE_Gate_StatusShow";

import { UserCreate } from "components/entities/users/UserCreate";
import { UserEdit } from "components/entities/users/UserEdit";
import { UserShow } from "components/entities/users/UserShow";
import { UsersList } from "components/entities/users/UsersList";

import { StatusEdit } from "components/entities/site_service/sites_statuses/StatusEdit";
import { StatusesList } from "components/entities/site_service/sites_statuses/StatusesList";
import { StatusShow } from "components/entities/site_service/sites_statuses/StatusShow";

import BidsCreate from "components/entities/regelleistung_service/bids/BidsCreate";
import BidsEdit from "components/entities/regelleistung_service/bids/BidsEdit";
import { BidsList } from "components/entities/regelleistung_service/bids/BidsList";
import BidsShow from "components/entities/regelleistung_service/bids/BidsShow";

import ManualBidsCreate from "components/entities/regelleistung_service/bids/ManualBidsCreate";

import PlanningMaskCreate from "components/entities/planning_service/plannings_masks/PlanningMaskCreate";

import { IecHealthCheck } from "components/entities/healthChecks/iec/IecHealthCheck";

import AfrrEngagementVisualization from "components/entities/affr_engagement_service/AfrrEngagementVisualization";
import ConfigContainer from "components/entities/config/ConfigContainer";
import { ConfigTabContent } from "components/entities/config/ConfigTabContent";
import ConfigPeriodicTasksShow from "components/entities/config/periodicTasks/ConfigPeriodicTasksShow";
import PeriodicTaskCreateForm from "components/entities/config/periodicTasks/PeriodicTaskCreateForm";
import ConfigWebhookCreateForm from "components/entities/config/webhooks/ConfigWebhookCreateForm";
import ConfigWebhookShow from "components/entities/config/webhooks/ConfigWebhookShow";
import EcpGateCreate from "components/entities/ecp/gates/EcpGateCreate";
import EcpGateEdit from "components/entities/ecp/gates/EcpGateEdit";
import EcpGateList from "components/entities/ecp/gates/EcpGateList";
import EcpGateShow from "components/entities/ecp/gates/EcpGateShow";
import { HealthChecksContainer } from "components/entities/healthChecks/HealthChecksContainer";
import { HealthChecksTabContent } from "components/entities/healthChecks/HealthChecksTabContent";
import { MaintenanceCreate } from "components/entities/maintenances/MaintenancesCreate";
import { MaintenancesEdit } from "components/entities/maintenances/MaintenancesEdit";
import { MaintenancesList } from "components/entities/maintenances/MaintenancesList";
import { MaintenancesShow } from "components/entities/maintenances/MaintenancesShow";
import BidSettingsCreate from "components/entities/optimizer/bid_settings/BidSettingsCreate";
import BidSettingsEdit from "components/entities/optimizer/bid_settings/BidSettingsEdit";
import BidSettingsList from "components/entities/optimizer/bid_settings/BidSettingsList";
import BidSettingsShow from "components/entities/optimizer/bid_settings/BidSettingsShow";
import CertificationsCreate from "components/entities/optimizer/certifications/CertificationsCreate";
import CertificationsEdit from "components/entities/optimizer/certifications/CertificationsEdit";
import CertificationsList from "components/entities/optimizer/certifications/CertificationsList";
import CertificationsShow from "components/entities/optimizer/certifications/CertificationsShow";
import CertificationsTestsCreate from "components/entities/optimizer/certifications_tests/CertificationsTestsCreate";
import CertificationsTestsEdit from "components/entities/optimizer/certifications_tests/CertificationsTestsEdit";
import CertificationsTestsList from "components/entities/optimizer/certifications_tests/CertificationsTestsList";
import CertificationsTestsShow from "components/entities/optimizer/certifications_tests/CertificationsTestsShow";
import GroupsCertificationsCreate from "components/entities/optimizer/groups_certifications/GroupsCertificationsCreate";
import GroupsCertificationsEdit from "components/entities/optimizer/groups_certifications/GroupsCertificationsEdit";
import GroupsCertificationsList from "components/entities/optimizer/groups_certifications/GroupsCertificationsList";
import GroupsCertificationsShow from "components/entities/optimizer/groups_certifications/GroupsCertificationsShow";
import OptiTasksList from "components/entities/optimizer/tasks/OptiTasksList";
import PlanningCreate from "components/entities/planning_service/plannings/PlanningCreate";
import PlanningEdit from "components/entities/planning_service/plannings/PlanningEdit";
import PlanningShow from "components/entities/planning_service/plannings/PlanningShow";
import PlanningsList from "components/entities/planning_service/plannings/PlanningsList";
import { PlanningMaskList } from "components/entities/planning_service/plannings_masks/PlanningMaskList";
import ResultsList from "components/entities/regelleistung_service/results/ResultsList";
import GenerationInitialForm from "components/entities/site_service/performances/dagGeneration/InitialForm";
import PerformanceList from "components/entities/site_service/performances/performanceValidation/PerformanceList";
import { SiteCreate } from "components/entities/site_service/sites/SiteCreate";
import { SiteEdit } from "components/entities/site_service/sites/SiteEdit";
import { SiteShow } from "components/entities/site_service/sites/SiteShow";
import { SiteList } from "components/entities/site_service/sites/SitesList";
import FakePasCreate from "components/entities/tsoTests/fake_pas/FakePasCreate";
import { FakePasList } from "components/entities/tsoTests/fake_pas/FakePasList";
import NlevelLossCreate from "components/entities/tsoTests/NLevelLoss/NlevelLossCreate";
import { NLevelLossList } from "components/entities/tsoTests/NLevelLoss/NLevelLossList";
import TestsAfrrRtePlanningsContainer from "components/entities/tsoTests/testsAfrrRte/TestsAfrrRtePlanningsContainer";
import TestsRtePlanningsContainer from "components/entities/tsoTests/testsRte/TestsRtePlanningsContainer";
import { baseResources } from "config_infos";
import { parseISO } from "date-fns";
import compositeDataProvider from "../dataproviders/compositeDataProvider";
import { i18nProvider } from "../i18n/i18nProvider";
import ReTheme from "../theme";

const { sites, topase, regelleistung, maintenance, users, plannings, iec, optimizer, afrrEngagement, ecp } =
  baseResources;

const CustomAppBar = () => <AppBar color="primary" />;

const CustomLayout = (props: any) => {
  return <Layout {...props} menu={AppMenu} appBar={CustomAppBar} />;
  7;
};

const App = () => (
  <Admin
    theme={ReTheme}
    dataProvider={compositeDataProvider}
    i18nProvider={i18nProvider}
    requireAuth
    layout={CustomLayout}
  >
    {(permissions) => {
      return (
        <>
          <Resource
            name={sites.SITES}
            options={{ label: "Site" }}
            list={SiteList}
            edit={SiteEdit}
            show={SiteShow}
            create={SiteCreate}
            recordRepresentation={(record) => {
              return `${record?.id} : ${record?.name}`;
            }}
          />
          <Resource
            name={sites.STATUSES}
            options={{ label: "Statuts des sites" }}
            list={StatusesList}
            show={StatusShow}
            edit={StatusEdit}
            recordRepresentation="name"
          />
          <Resource
            name={sites.GROUPS}
            list={GroupList}
            show={GroupShow}
            edit={GroupEdit}
            create={GroupCreate}
            recordRepresentation={(record) => {
              return `${record?.id} : ${record?.name} - ${record?.code}`;
            }}
          />
          <Resource name={sites.TELEMETRY_1S} recordRepresentation="timestamp" />
          <Resource name={sites.NLEVELS} recordRepresentation="timestamp" />
          <Resource
            name={sites.PERFORMANCE_CHRONICLES}
            list={PerformanceList}
            options={{ label: "Performance Chronicles" }}
          />

          <Resource name={sites.PERFORMANCE_CHRONICLES_RESULTS} />

          <Resource name={sites.CONFIGURATIONS} />

          <Resource
            name={topase.PA}
            options={{ label: "PA" }}
            list={PAList}
            edit={PAEdit}
            show={PAShow}
            create={PACreate}
            recordRepresentation="name"
          />
          <Resource
            name={topase.RTE_GATE}
            options={{ label: "RTE Gate" }}
            list={RTE_GateList}
            show={RTE_GateShow}
            edit={RTE_GateEdit}
            create={RTE_GateCreate}
            recordRepresentation="name"
          />
          <Resource
            name={topase.RTE_GATE_STATUS}
            options={{ label: "RTE Gate Status" }}
            list={RTE_Gate_StatusList}
            show={RTE_Gate_StatusShow}
            edit={RTE_Gate_StatusEdit}
            create={RTE_Gate_StatusCreate}
            recordRepresentation="name"
          />
          <Resource
            name={regelleistung.BIDS}
            options={{ label: "Bids" }}
            list={BidsList}
            show={BidsShow}
            create={BidsCreate}
          />
          <Resource name={regelleistung.BIDS_BATCH} />
          <Resource
            name={regelleistung.MANUAL_BIDS}
            options={{ label: "Manual Bids" }}
            list={BidsList}
            show={BidsShow}
            edit={BidsEdit}
            create={ManualBidsCreate}
          />
          <Resource name={regelleistung.RESULTS} options={{ label: "Results" }} list={ResultsList} />
          <Resource
            name={plannings.PLANNINGS}
            options={{ label: "Plannings" }}
            list={PlanningsList}
            show={PlanningShow}
            edit={PlanningEdit}
            create={PlanningCreate}
            recordRepresentation={(record) => {
              return `site ${record?.site_id} : ${parseISO(record?.start_date)}`;
            }}
          />
          <Resource
            name={plannings.PLANNING_MASKS}
            options={{ label: "Planning Masks" }}
            list={PlanningMaskList}
            create={PlanningMaskCreate}
          />
          <Resource
            name={maintenance.MAINTENANCE}
            options={{ label: "Maintenances" }}
            list={MaintenancesList}
            show={MaintenancesShow}
            edit={MaintenancesEdit}
            create={MaintenanceCreate}
            recordRepresentation="name"
          />
          <Resource
            name={optimizer.BID_SETTINGS}
            list={BidSettingsList}
            show={BidSettingsShow}
            edit={BidSettingsEdit}
            create={BidSettingsCreate}
          />
          <Resource
            name={optimizer.CERTIFICATIONS_TESTS}
            list={CertificationsTestsList}
            show={CertificationsTestsShow}
            edit={CertificationsTestsEdit}
            create={CertificationsTestsCreate}
          />
          <Resource
            name={optimizer.CERTIFICATIONS}
            list={CertificationsList}
            show={CertificationsShow}
            edit={CertificationsEdit}
            create={CertificationsCreate}
            recordRepresentation="certif_name"
          />
          <Resource
            name={optimizer.GROUPS_CERTIFICATIONS}
            list={GroupsCertificationsList}
            show={GroupsCertificationsShow}
            edit={GroupsCertificationsEdit}
            create={GroupsCertificationsCreate}
          />

          <Resource name={afrrEngagement.POINT} />

          <Resource name={afrrEngagement.BID_TIME_SERIES} />

          <Resource name={ecp.BIDS} />
          <Resource
            name={ecp.GATES}
            list={EcpGateList}
            show={EcpGateShow}
            edit={EcpGateEdit}
            create={EcpGateCreate}
            options={{ label: "ECP" }}
          />

          <Resource
            name={users.USERS}
            options={{ label: "Utilisateurs" }}
            list={UsersList}
            show={UserShow}
            edit={UserEdit}
            create={permissions === "admin" ? UserCreate : undefined}
            recordRepresentation="name"
          />
          <Resource
            name={iec.RTE_AFRR_TEST_CONFIG}
            options={{ label: "N level loss" }}
            list={NLevelLossList}
            create={NlevelLossCreate}
            // recordRepresentation="name"
          />

          <Resource name={iec.HEALTH_CHECK} options={{ label: "IEC Health check" }} recordRepresentation="name" />
          <Resource name={iec.GROUPS} recordRepresentation="code" />
          <Resource name={iec.FAKE_PAS} list={FakePasList} create={FakePasCreate} />
          <Resource name={regelleistung.PERIODIC_TASKS} />
          <Resource name={sites.WEBHOOKS} />
          <Resource name={sites.PERIODIC_TASKS} />
          <Resource name={plannings.WEBHOOKS} />
          <Resource name={plannings.PERIODIC_TASKS} />
          <Resource name={iec.PERIODIC_TASKS} />
          <Resource name={topase.WEBHOOKS} />
          <Resource name={maintenance.WEBHOOKS} />
          <Resource name={users.WEBHOOKS} />
          <Resource name={iec.WEBHOOKS} />

          <CustomRoutes>
            <Route path="/config" element={<ConfigContainer />}>
              <Route path={`:service`} element={<ConfigTabContent />} />
              <Route path=":service/webhooks/create" element={<ConfigWebhookCreateForm />} />
              <Route path=":service/periodic_tasks/create" element={<PeriodicTaskCreateForm />} />
              <Route path=":service/webhooks/:webhookId" element={<ConfigWebhookShow type="edit" />} />
              <Route path=":service/periodic_tasks/:taskId" element={<ConfigPeriodicTasksShow type="edit" />} />
              <Route path=":service/webhooks/:webhookId/show" element={<ConfigWebhookShow />} />
              <Route path=":service/periodic_tasks/:taskId/show" element={<ConfigPeriodicTasksShow />} />
            </Route>
            <Route path="/healthchecks" element={<HealthChecksContainer />}>
              <Route path={`:service`} element={<HealthChecksTabContent />} />
              <Route path=":service/iec" element={<IecHealthCheck />} />
            </Route>
            <Route path="/performance-chronicles" element={<PerformanceList />} />
            <Route path="/performance-chronicles-generation" element={<GenerationInitialForm />} />
            <Route path="/tests-rte" element={<TestsRtePlanningsContainer />} />
            <Route path="/tests-afrr-rte" element={<TestsAfrrRtePlanningsContainer />} />
            <Route path="/tests-fcr-rte" element={<TestsRtePlanningsContainer />} />
            <Route path="/afrr-engagement" element={<AfrrEngagementVisualization />}></Route>
            <Route path={`/${baseResources.optimizer.TASKS}`} element={<OptiTasksList />}></Route>
          </CustomRoutes>
        </>
      );
    }}
  </Admin>
);

export default App;
